import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Jumbotron from './components/Jumbotron';
import Card from './components/Card';
import SignUpModal from './components/SignUpModal';
import ForgotPasswordModal from './components/ForgotPasswordModal';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <br></br>
      <Jumbotron/>
      <br></br>
      <br></br>
      <br></br>
      <Card/>
      <SignUpModal/>
      <ForgotPasswordModal/>
      <Footer/>
    </div>
  );
}

export default App;
