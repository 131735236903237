import React from 'react';

function ForgotPasswordModal () {
  return (
    <div className="modal fade" id="forgotPasswordModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="forgotPasswordModalLabel">Reset password</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form id="forgotPasswordForm">
            <div className="modal-body form-group">
              <input type="email" className="form-control" placeholder="email@example.com" aria-label="Email" aria-describedby="basic-addon2" id="forgotPasswordEmailInput" required></input>
            </div>
            <div className="modal-footer form-group">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="submit" className="btn btn-primary forgotPasswordSendButton">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordModal;