import React from 'react';
import RenameModal from './RenameModal';
import './Card.css';

function Card () {
  return (
  <div className="card text-center mx-auto d-none" id="userSection">
    <div className="card-header">
      <h4>Output is <span id="currentState"></span></h4>
      <p class="card-text" id="lastReportedOnline"></p>
    </div>
    <div className="card-body">
      <div className="input-group w-75 mx-auto">
        <select className="custom-select" id="outputSelector">
          {/*
          <option>R0</option>
          <option>R1</option>
          <option>R2</option>
          <option>R3</option>
          <option>R4</option>
          <option>R5</option>
          <option>R6</option>
          <option>R7</option>
          */}
        </select>
        <div className="input-group-append">
          {/* Button trigger modal */}
          <button type="button" className="btn btn-outline-secondary" data-toggle="modal" data-target="#renameModal">
            Edit
          </button>
        </div>
      </div>
      <RenameModal/>
    <div>
      <br></br>
      <div className="btn-group">
        <button type="button" className="btn btn-primary button-toggle">Toggle</button>
        <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        {/* Buttons dropdown */}
        <div className="dropdown-menu" aria-labelledby="dropdownMenuReference">
          <button className="dropdown-item button-on" >On</button>
          <button className="dropdown-item all-on">All On</button>
          <button className="dropdown-item button-off">Off</button>
          <button className="dropdown-item all-off">All Off</button>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}

export default Card;