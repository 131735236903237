import React from 'react';

function SignUpModal () {
  return (
  <div className="modal fade" id="signUpModal" tabIndex="-1" role="dialog" aria-labelledby="signUpModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="signUpModalLabel">Please fill to sign up</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form id="signUpForm">
          <div className="modal-body form-group">
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName">First name</label>
                <input type="text" className="form-control" id="firstName" placeholder="First name" required></input>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName">Last name</label>
                <input type="text" className="form-control" id="lastName" placeholder="Last name" required></input>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="emailSignUp">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Email" required></input>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="passwordSignUp">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Password" required></input>
                </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="country">Country</label>
                <input type="text" className="form-control" id="country" placeholder="Country" defaultValue="Dominican Republic" required></input>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="state">State</label>
                <input type="text" className="form-control" id="state" placeholder="State or Province" defaultValue="Santiago" required></input>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="city">City</label>
                <input type="text" className="form-control" id="city" placeholder="City" defaultValue="Santiago" required></input>
              </div>
              <div className="col-md-8 mb-3">
                <label htmlFor="streetAddress">Street Address</label>
                <input type="text" className="form-control" id="streetAddress" placeholder="Street Address" required></input>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="zipCode">Zip</label>
                <input type="text" className="form-control" id="zipCode" placeholder="Zip" defaultValue="51000" required></input>
              </div>
            </div>
            <div className="form-group">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="termsAndConditions" required></input>
                <label className="form-check-label" htmlFor="termsAndConditions">Agree to terms and conditions</label>
              </div>
            </div>
          </div>
          <div className="modal-footer form-group">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" className="btn btn-primary SignUpSubmitButton">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
}

export default SignUpModal;