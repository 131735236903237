import React from 'react';

function RenameModal () {
  return (
    <div className="modal fade" id="renameModal" tabIndex="-1" role="dialog" aria-labelledby="renameModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="renameModalLabel">Rename current selection</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form id="renameForm">
            <div className="modal-body form-group">
              <input type="text" className="form-control" placeholder="Insert new name and save changes" aria-label="New name" aria-describedby="basic-addon2" id="renameInputText" required></input>
            </div>
            <div className="modal-footer form-group">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="submit" className="btn btn-primary button-rename">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RenameModal;