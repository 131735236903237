import React from 'react';

function Navbar () {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <a className="navbar-brand" href="/">Normally Open</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigationBar" aria-controls="navigationBar" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navigationBar">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown dropleft d-none" id="signin-dropdown">
            <a href="/" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sign In</a>
            <div className="dropdown-menu">
              <form className="px-4 py-3" id="signInForm">
                <div className="form-group">
                  <label htmlFor="inputEmail">Email address</label>
                  <input type="email" className="form-control" id="inputEmail" placeholder="email@example.com"></input>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPassword">Password</label>
                  <input type="password" className="form-control" id="inputPassword" placeholder="Password"></input>
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="rememberMeCheck"></input>
                  <label className="form-check-label" htmlFor="rememberMeCheck">Remember me</label>
                </div>
                <button type="submit" className="btn btn-primary">Sign in</button>
              </form>
             <div className="dropdown-divider"></div>
              <a className="dropdown-item" data-toggle="modal" data-target="#signUpModal" href="#signUpModal">New around here? Sign up</a>
              <a className="dropdown-item" data-toggle="modal" data-target="#forgotPasswordModal" href="#forgotPasswordModal">Forgot password?</a>
             </div>
          </li>
          <li className="nav-item dropdown d-none" id="user-dropdown">
            <a href="/" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="current-user"> </a>
            <div className="dropdown-menu">
              <button className="btn btn-secondary btn-md button-signout dropdown-item d-none" id="button-signout">Sign Out</button>
            </div>
          </li>
        </ul>
     </div>
    </nav>
  )
}

export default Navbar;