import React from 'react';

function Jumbotron () {
  return (
  <div className="jumbotron d-none" id="jumbotronSection">
    <div className="container">
      <h1>Control your home</h1>
      <p>Anywhere from your personal computer or smartphone.</p>
      <p>
        <button className="btn btn-primary btn-lg button-signup" data-toggle="modal" data-target="#signUpModal">Sign Up</button>
      </p>
    </div>
  </div>
  );
}

export default Jumbotron;